import { memo, useContext, useEffect } from 'react'

import { Box } from '@material-ui/core'

import { PlaylistContext } from 'pages/playlist/PagePlaylist'
import {
    VideoPlayer,
    VideoOverlayWrapper,
    CallToActionWrapper,
    checkToOpenPlaybookPauseScreen,
    PlaybookPauseScreenWrapper
} from 'components'

import { logToAnalytics, playbookToAnalyticsProps } from 'modules'
import {
    useBoolean,
    useVideoLoadAnalytics,
    CollectVideoPlayAnalytics
} from 'hooks'

import {
    PlaybookType,
    PlaylistType,
    QuickGuiddeType,
    VideoOverlayDataType
} from 'app/types'

type Props = {
    playlist: PlaylistType
    playbook: PlaybookType | QuickGuiddeType
    overlayData: VideoOverlayDataType
}

export const VideoPanel = memo(({ playlist, playbook, overlayData }: Props) => {
    const showVideoOverlay = useBoolean()
    const hoverState = useBoolean()

    const { videoRef, activePlaybook, setActivePlaybook, playbookList } =
        useContext(PlaylistContext)

    const playlistAutoPlay = useBoolean(true)
    const showedPauseScreen = useBoolean()
    const resetShowedPauseScreen = showedPauseScreen.setFalse
    useEffect(
        () => resetShowedPauseScreen(),
        [activePlaybook, resetShowedPauseScreen]
    )

    const playlistId = playlist.id

    useVideoLoadAnalytics({ playbook: playlist, playbookList })

    useEffect(() => {
        if (playlist) {
            logToAnalytics('open_playlist', {
                'playlist-app':
                    playlist.applications?.[0]?.applicationName || null,
                'playlist-appId':
                    playlist.applications?.[0]?.applicationId || null,
                'playlist-tags': playlist.tags,
                'playlist-id': playlistId,
                'playlist-title': playlist.title
            })
        }
    }, [playlist, playlistId])

    const isLastPlaybookActive = activePlaybook === playbookList.length - 1

    const playlistAutoPlayIsEnabled =
        playlistAutoPlay.isTrue &&
        !isLastPlaybookActive &&
        videoRef.currentTime !== playbook.duration

    const playNextPlaylistVideo = () => {
        showVideoOverlay.setFalse()

        setActivePlaybook(isLastPlaybookActive ? 0 : activePlaybook + 1)

        videoRef.current.currentTime = 0
    }

    if (!playbook) return null

    return (
        <Box position="relative">
            <CollectVideoPlayAnalytics
                playbook={playbook}
                playlist={playlist}
                videoElement={videoRef.current}
            />
            <VideoPlayer
                ref={videoRef}
                hideReactions
                hoverState={hoverState}
                playbook={playbook}
                playlistAutoPlay={
                    playbookList.length > 1 ? playlistAutoPlay : undefined
                }
                hideWatermark={showVideoOverlay.isTrue}
                playlist={playlist}
                showGButtonAsIcon={overlayData.showGButtonAsIconInEmbed}
                onPlay={() => {
                    logToAnalytics('videoPlayClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        'playlist-id': playlistId,
                        'playlist-title': playlist.title
                    })
                }}
                onPause={() => {
                    if (playlistAutoPlayIsEnabled) return

                    logToAnalytics('videoStopClicked', {
                        ...playbookToAnalyticsProps(playbook),
                        'playlist-id': playlistId,
                        'playlist-title': playlist.title
                    })
                    checkToOpenPlaybookPauseScreen({
                        videoRef,
                        overlayData,
                        openPauseScreen: showedPauseScreen.setTrue,
                        isPauseScreenShowed: showedPauseScreen.isTrue
                    })
                }}
                onEnded={() => {
                    if (playlistAutoPlay.isTrue) {
                        playNextPlaylistVideo()
                    } else {
                        showVideoOverlay.setTrue()
                    }
                }}
            />

            {showedPauseScreen.isTrue && (
                <PlaybookPauseScreenWrapper playbook={playbook} />
            )}

            {playlistAutoPlay.isFalse && (
                <>
                    <CallToActionWrapper
                        playbook={playbook}
                        videoElement={videoRef.current}
                        onLastStepSkip={
                            !overlayData?.hideNewPublicEmbedEnding
                                ? showVideoOverlay.setTrue
                                : undefined
                        }
                    />

                    <VideoOverlayWrapper
                        showVideoOverlay={showVideoOverlay}
                        videoRef={videoRef}
                        playbook={playbook}
                        overlayData={overlayData}
                        onWatchClick={() => {
                            hoverState.setTrue()

                            if (isLastPlaybookActive) {
                                setActivePlaybook(0)
                                showVideoOverlay.setFalse()
                            } else {
                                setActivePlaybook(activePlaybook + 1)
                            }
                        }}
                        watchLabel={
                            isLastPlaybookActive
                                ? 'Watch Again'
                                : 'Watch next video'
                        }
                    />
                </>
            )}
        </Box>
    )
})
