import firebase from 'firebase'
import { envConfig } from './envConfig'
import {
    AnyPlaybookType,
    PlaylistType,
    QuickGuiddeType,
    SingleCtaType
} from 'app/types'

const PRODUCTION_PROJECT = 'guidde-production'
export const host = `https://${envConfig.firebaseConfig.authDomain}`

export const isDev = envConfig.firebaseConfig.projectId !== PRODUCTION_PROJECT
export const isLocalhost = process.env.NODE_ENV === 'development'

export const isQG = (
    playbook: AnyPlaybookType
): playbook is QuickGuiddeType => {
    return playbook.mode === 'quickGuidde'
}

export const isPlaylist = (
    playbook: AnyPlaybookType
): playbook is PlaylistType => {
    return playbook.mode === 'playlist'
}

export const delay = (time: number) =>
    // @ts-ignore
    new Promise(resolve => setTimeout(resolve, time)).then(clearTimeout)

export const jsonError = 'RESPONSE_IN_NOT_JSON'

export const request = async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    body?: any
) => {
    const token = await firebase.auth().currentUser?.getIdToken()

    if (!token) return

    return fetch(`${host}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-client': 'embed',
            authorization: `Bearer ${token}`
        },
        ...(body && { body: JSON.stringify(body) })
    }).then(async res => {
        if (!res.ok) {
            try {
                return res.json().then(err => {
                    return Promise.reject(err)
                })
            } catch (e) {
                return Promise.reject('Something went wrong')
            }
        }
        return Promise.resolve(res.json())
    })
}

export const option = (value: any, label: any) => ({ value, label })

export const uniqueArray = (a: Array<any>) =>
    // @ts-ignore
    [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

export const round = (number: number) => Math.round(number * 100) / 100

export function formatTime(seconds: number) {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

export const formatDate = (seconds: number) => {
    return new Date(seconds * 1000).toString().substring(4, 16)
}

export const timeToSeconds = (time?: string) => {
    if (!time) return 0

    return time
        .split(':')
        .reverse()
        .reduce((prev: any, curr: any, i) => prev + curr * Math.pow(60, i), 0)
}

export const percentToTime = (duration: number, percent = 0) =>
    (duration / 100) * percent

export const tagRegex = new RegExp(/^[a-zA-Z0-9_.-]*$/)

export const domainValidation = (domains: string) =>
    new RegExp(
        `^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+.)?[a-zA-Z]+.)?(${domains})$`
    )

export const uiId = (id: string) => `ui-t-${id}`

export const getUserBrowser = () => {
    const sUsrAg = navigator.userAgent
    let sBrowser

    if (sUsrAg.indexOf('Firefox') > -1) {
        sBrowser = 'firefox'
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
        sBrowser = 'samsung_internet'
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
        sBrowser = 'opera'
    } else if (sUsrAg.indexOf('Trident') > -1) {
        sBrowser = 'ie'
    } else if (sUsrAg.indexOf('Edg') > -1 || sUsrAg.indexOf('Edge') > -1) {
        sBrowser = 'edge'
    } else if (sUsrAg.indexOf('Chrome') > -1) {
        sBrowser = 'chromium'
    } else if (sUsrAg.indexOf('Safari') > -1) {
        sBrowser = 'safari'
    } else {
        sBrowser = 'unknown'
    }
    return sBrowser
}

export const copyToClipboard = (data: string, callback: () => void) => {
    if (!navigator?.clipboard) {
        return Promise.reject({ message: 'Clipboard API not supported' })
    }

    return navigator.clipboard
        .writeText(data)
        .then(callback)
        .catch(e => console.error('COPY LINK ERROR:', e))
}

export const isColorLight = (color: string) => {
    const hex = color.replace('#', '')

    const r = parseInt(hex.substr(0, 2), 16)
    const g = parseInt(hex.substr(2, 2), 16)
    const b = parseInt(hex.substr(4, 2), 16)

    const brightness = (r * 299 + g * 587 + b * 114) / 1000

    return brightness > 155
}

type OpenType = '_blank' | '_self'
export const openLink = (link: string, type: OpenType = '_blank') => {
    window.open(link, type)
}

export const generateUrlWithDomain = (path: string) => {
    return `https://${envConfig.firebaseConfig.authDomain}${[path]}`
}

export const getRealCtaLink = (cta: SingleCtaType['action']) => {
    return !cta.link.startsWith('http://') && !cta.link.startsWith('https://')
        ? `https://${cta.link}`
        : cta.link
}

export const isMobileDevice = () => {
    return [
        /Android/i,
        /BlackBerry/i,
        /iPhone|iPad|iPod/i,
        /Opera Mini/i,
        /IEMobile/i,
        /WPDesktop/i
    ].some(it => navigator?.userAgent?.match(it))
}
